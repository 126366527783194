import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.split.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
import $ from 'jquery';
export default {
  data: function data() {
    return {
      bordered: false,
      meterIds: [],
      meterList: [],
      timer_meter: null
    };
  },
  mounted: function mounted() {
    var token = localStorage.getItem("currentUser_token");

    if (!token && typeof token != "undefined" && token != 0) {
      this.$router.replace({
        path: '/'
      });
      return;
    }

    var id = localStorage.getItem('stationId');

    if (!id && typeof id != "undefined" && id != 0) {
      this.$router.replace({
        path: '/distribution'
      });
    }

    var this_ = this;
    this.getMeters();
    this_.timer_meter = window.setInterval(function () {
      this_.getMetersData();
    }, 3000);
  },
  destroyed: function destroyed() {
    clearInterval(this.timer_meter);
  },
  methods: {
    getMeters: function getMeters() {
      var this_ = this;
      axios({
        method: 'get',
        url: '/emind/config/equipment/meter/' + localStorage.getItem('stationId')
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code === '0') {
          this_.meterList = d.data.data;
          this_.meterList.sort(this_.sortId);
          this_.meterIds = [];
          this_.meterList.map(function (item) {
            this_.meterIds.push(item.id);
            item.name = item.numbering.split('-').length == 2 ? item.name : item.parentEquipmentName + '-' + item.num + '#' + item.typeName;
          });
          this_.getMetersData();
        } else {
          this_.$message.error('请求出错', 2, function () {});
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    getRealValue: function getRealValue(value, unit, isNullValue, mudel) {
      if (value == null) {
        return isNullValue;
      } else {
        return value / mudel + unit;
      }
    },
    getMetersData: function getMetersData() {
      var this_ = this;
      axios({
        method: 'post',
        url: '/emind/config/equipment/meter/data',
        data: this_.meterIds
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code === '0') {
          d.data.data.map(function (item) {
            $("#" + item.id + "_voltageA").html(this_.getRealValue(item.phaseVoltage_UA, "V", "未知", 1));
            $("#" + item.id + "_voltageB").html(this_.getRealValue(item.phaseVoltage_UB, "V", "未知", 1));
            $("#" + item.id + "_voltageC").html(this_.getRealValue(item.phaseVoltage_UC, "V", "未知", 1));
            $("#" + item.id + "_currentA").html(this_.getRealValue(item.phaseCurrent_A, "A", "未知", 1));
            $("#" + item.id + "_currentB").html(this_.getRealValue(item.phaseCurrent_B, "A", "未知", 1));
            $("#" + item.id + "_currentC").html(this_.getRealValue(item.phaseCurrent_C, "A", "未知", 1));
            $("#" + item.id + "_activePowerA").html(this_.getRealValue(item.phaseActivePower_A, "kW", "未知", 1));
            $("#" + item.id + "_activePowerB").html(this_.getRealValue(item.phaseActivePower_B, "kW", "未知", 1));
            $("#" + item.id + "_activePowerC").html(this_.getRealValue(item.phaseActivePower_C, "kW", "未知", 1));
            $("#" + item.id + "_activePowerT").html(this_.getRealValue(item.phaseActivePower_T, "kW", "未知", 1));
            $("#" + item.id + "_reactivePowerA").html(this_.getRealValue(item.phaseReactivePower_A, "kVar", "未知", 1));
            $("#" + item.id + "_reactivePowerB").html(this_.getRealValue(item.phaseReactivePower_B, "kVar", "未知", 1));
            $("#" + item.id + "_reactivePowerC").html(this_.getRealValue(item.phaseReactivePower_C, "kVar", "未知", 1));
            $("#" + item.id + "_reactivePowerT").html(this_.getRealValue(item.phaseReactivePower_T, "kVar", "未知", 1));
            $("#" + item.id + "_powerFactorA").html(this_.getRealValue(item.phasePowerFactor_A, "", "未知", 1));
            $("#" + item.id + "_powerFactorB").html(this_.getRealValue(item.phasePowerFactor_B, "", "未知", 1));
            $("#" + item.id + "_powerFactorC").html(this_.getRealValue(item.phasePowerFactor_C, "", "未知", 1));
            $("#" + item.id + "_powerFactorT").html(this_.getRealValue(item.phasePowerFactor_T, "", "未知", 1));
            $("#" + item.id + "_frequency").html(this_.getRealValue(item.frequency, "Hz", "未知", 1));
            $("#" + item.id + "_positiveEnergy").html(this_.getRealValue(item.positiveActiveEnergy, "kWh", "未知", 10));
            $("#" + item.id + "_reverseEnergy").html(this_.getRealValue(item.reverseActiveEnergy, "kWh", "未知", 10));
          });
        } else {
          this_.$message.error('请求出错', 2, function () {});
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    sortId: function sortId(a, b) {
      return (a.id - b.id) * 1;
    }
  }
};